import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ticket_yuki from "../img/home_yuki.jpeg";
import ticket_cyber from "../img/home_cybercity.jpeg";
import ticket_combi from "../img/home_combi.jpg";
import logo from "../img/illuminarium.png";


import Loading from "./components/Loading";
import "../css/style.css"

const Home = () => {

    const [eventGroupIdYukiShow, setEventGroupIdYukiShow] = useState("");
    const [eventGroupIdDigitalArtShow, setEventGroupIdDigitalArtShow] = useState("");
    const [eventGroupIdKombiTickets, setEventGroupIdKombiTickets] = useState("");
    const [loading, setLoading] = useState(true);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    const loadToken = () => {
        axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {

            setEventGroupIdYukiShow(res.data.eventGroupIdYukiShow);
            setEventGroupIdDigitalArtShow(res.data.eventGroupIdDigitalArtShow);
            setEventGroupIdKombiTickets(res.data.eventGroupIdKombiTickets);

            setLoading(false);
        });
    };

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div>
            {/* loop the ticket types */}
            {loading ? (
                <Loading
                    alignment="center"
                    color="#d3d3d3"
                    bgColor="transparent"
                    position="fixed"
                    top="50%"
                    left="50%"
                />
            ) : (
                <>
                    {resources.translation && (
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 text-center">
                                    <img className="img-fluid logo" src={logo} alt="img" />
                                </div>

                                <div className="col-md-6">
                                    <div className="row mt-5">
                                        <div className="col-md-5 f-size-20px">
                                            <h1>BUCH DEIN TICKET ONLINE</h1>
                                        </div>
                                        <div className="col-md-7">
                                            <p className="fw-bold pb-2">Der Eintritt zum Illuminarium ist frei. Tickets für die Lichtshows können hier gekauft werden.</p>
                                                <p className="mt-4"><h4 className="fw-bold">YUKI'S KLANGVOLLE WEIHNACHTEN</h4></p>
                                            <p className="mt-4 pb-2">
                                                <b>Einlass:</b> 17:00 / 17:45 / 18:30 / 19:15<br />
                                                <b>Showbeginn:</b> 15 Minuten nach Einlass<br />
                                                <b>Showdauer:</b> 25 Minuten
                                            </p>
                                            <p className="mt-4"><h4 className="fw-bold">DIGITAL ART SHOW ODYSSEY IN SPACE</h4></p>
                                            <p className="mt-4 pb-2">
                                                <b>Einlass:</b> 20:00 / 20:30 / 21:00 / 21:30<br />
                                                <b>Showbeginn:</b> 5 Minuten nach Einlass<br />
                                                <b>Showdauer:</b> 25 Minuten
                                            </p>
                                            <p>
                                                <b>Bestellhotline Tickets (Ticketino):</b> 0900 441 441 (CHF 1.00/Minute)<br />
                                                <b>Für alle weiteren Fragen:</b> <a href="mailto:info@illuminarium.ch">info@illuminarium.ch</a>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row text-center mt-5">
                                        <div className="col-md-4">
                                            <img src={ticket_yuki} className="img-fluid ps-1 pe-1"></img>
                                            <h5 className="mt-3">YUKI SHOW</h5>
                                            <a className="btn custom-button mt-2 mb-4" onClick={() => navigate(`/${language}/timetable/${eventGroupIdYukiShow}`)}>TICKETS BUCHEN</a>
                                        </div>

                                        <div className="col-md-4">
                                            <img src={ticket_cyber} className="img-fluid ps-1 pe-1"></img>
                                            <h5 className="mt-3">DIGITAL ART SHOW</h5>
                                            <a className="btn custom-button mt-2 mb-4" onClick={() => navigate(`/${language}/timetable/${eventGroupIdDigitalArtShow}`)}>TICKETS BUCHEN</a>
                                        </div>

                                        <div className="col-md-4">
                                            <img src={ticket_combi} className="img-fluid ps-1 pe-1"></img>
                                            <h5 className="mt-3">KOMBI TICKETS</h5>
                                            <a className="btn custom-button mt-2" onClick={() => navigate(`/${language}/kombi/${eventGroupIdKombiTickets}`)}>TICKETS BUCHEN</a>
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-4">
                                        <div className="col-6 text-start">
                                            <a className="btn custom-button" href="https://illuminarium.ch/">
                                                {resources.translation._Back}
                                            </a>
                                        </div>
                                    </div>
                                    <p className="mt-5 text-center">
                                        {resources.translation && (
                                            <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Home;
