import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// MediData
import Home from "./views/Home";
import Kombi from "./views/Step05_KombiTicketsQuantity";
import TicketCalendar from "./views/Step10_TicketCalendar";
import Tickets from "./views/Step20_Tickets";
import Shipping from "./views/Step30_Shipping";
import Checkout from "./views/Step40_Checkout";
import Confirmation from "./views/Step50_Confirmation";

// Fallback page
import PageNotFound from "./views/components/PageNotFound";

function App() {
    return (
        <BrowserRouter>
        <Routes>
        {/*<Route path="/" element={<Navigate to="/de/2024/home" replace />} />*/}
        <Route path="*" element={<PageNotFound />} />

        <Route path="/:language/2024/home" element={<Home />} />
        <Route path="/:language/kombi/:eventGroupId" element={<Kombi />} />
        <Route path="/:language/timetable/:eventGroupId" element={<TicketCalendar />} />
        <Route path="/:language/timetable/:eventGroupId/:kombiTicketsQuantity" element={<TicketCalendar />} />
        <Route path="/:language/tickets/:eventGroupId/:eventId" element={<Tickets />} />
        <Route path="/:language/tickets/:eventGroupId/:eventId/:eventId2/:kombiTicketsQuantity" element={<Tickets />} />
        <Route path="/:language/shipping" element={<Shipping />} />
        <Route path="/:language/checkout" element={<Checkout />} />
        <Route path="/:language/confirmation" element={<Confirmation />} />
        <Route path="/:language/confirmation/:orderId" element={<Confirmation />} />
        </Routes>
        </BrowserRouter>
);
}

export default App;