import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Reducer from "../utilities/Reducer";

import logo from "../img/illuminarium.png";
import "../css/style.css";

import { api, getEventInfosByEventId } from "ticketino-api-client";

const Step40_Checkout = () => {
    const [show, setShow] = useState(0);
    const [resources, setResources] = useState({});
    const [EventGroupId] = useState(sessionStorage.getItem("EventGroupId"));
    const [eventGroupIdKombiTickets, setEventGroupIdKombiTickets] = useState(0);
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [order, setOrder] = useState([]);
    const [orderInfo, setOrderInfo] = useState({});
    const [bookedTicketTypeInfo, setBookedTicketTypeInfo] = useState([]);
    const [requested, setRequested] = useState(0);
    const [insurance, setInsurance] = useState(true);
    const [insuranceTotalAmount, setInsuranceTotalAmount] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [promotionCode, setPromotionCode] = useState("");
    const [promotionError, setPromotionError] = useState({
        text: "",
        valid: false
    });
    const [addedPromotionCodes, setAddedPromotionCodes] = useState([]);

    // just needed to group by event name on summary
    const [TicketTypesEvent] = useState(sessionStorage.getItem("TicketTypesEvent"));
    const [Event1Info] = useState(sessionStorage.getItem("Event1Info"));
    const [Event2Info] = useState(sessionStorage.getItem("Event2Info"));

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const { language } = useParams();

    const [languageInfo, dispatch] = useReducer(Reducer, {
        id: 0,
        language: language,
    });

    const [payment, setPayment] = useState({
        currency: "CHF",
        paymentType: 0,
        deliveryType: 0,
    });

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    useEffect(() => {
        if (requested === 0) {
            requestCompletedOrder();
        }

        setOrderInsuranceTotalAmount();

        if (orderInfo && orderInfo.totalInsuranceAmount > 0) {
            setInsurance(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        const requestFormSettings = async () => {
            try {
                const res = await axios.get(`form/formsettings`);
                setEventGroupIds(res.data);
            } catch (error) {
                console.error(error);
            }
        };

        const setEventGroupIds = (data) => {
            setEventGroupIdKombiTickets(data.eventGroupIdKombiTickets);
        };

        requestFormSettings();
        requestResources();
    }, [language]);

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const requestCompletedOrder = () => {
        axios
            .get(`${baseUrl}/ShopBasket/Order/${orderId}`)
            .then((res) => {
                setOrder(res.data.tickets);
                setOrderInfo(res.data);
                setTotalPrice(res.data.totalPrice);

                if (res.data.totalPrice == 0) {
                    setInsurance(false);
                }

                setPayment({
                    ...payment,
                    deliveryType: res.data.deliveryMethodId,
                    paymentType: res.data.paymentMethodId,
                });
                res.data.tickets.map((t) => requestBookedTicketTypeInfo(t.ticketTypeId));

                // prevent overwriting
                setRequested(requested + 1);
            })
            .catch((error) => {
                console.error(error.response.data);
                navigate(`/${language}/sessiontimeout`);
            });
    };

    const setTotalAmountWithInsurance = (condition) => {
        setInsurance(!condition);

        if (!condition) {
            setTotalPrice(totalPrice + insuranceTotalAmount);
        } else {
            setTotalPrice(totalPrice - insuranceTotalAmount);
        }
    };

    const requestBookedTicketTypeInfo = (ticketTypeId) => {
        axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Infos`)
            .then((res) => {

                let ticketTypeInfo = res.data.ticketTypeInfos.find((tti) => tti.languageId === languageInfo.id);

                const ticketTypesEventParsed = JSON.parse(TicketTypesEvent);

                const eventId = ticketTypesEventParsed.find((tte) => tte.id == ticketTypeId).eventId;

                const event1InfoParsed = JSON.parse(Event1Info);
                const event2InfoParsed = JSON.parse(Event2Info);

                let eventInfoName = event1InfoParsed.find((ei) => ei.eventId == eventId && ei.languageId == 0)?.name;

                if (eventInfoName === undefined) {
                    eventInfoName = event2InfoParsed.find((ei) => ei.eventId == eventId && ei.languageId == 0)?.name;
                }

                ticketTypeInfo.eventName = eventInfoName;
                ticketTypeInfo.eventId = eventId;

                setBookedTicketTypeInfo((current) => [
                    ...current,
                    ticketTypeInfo,
                ]);
                setShow(true);
            });
    };

    const changeDeliveryType = (deliveryType) => {
        axios
            .put(
                `${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/${deliveryType}`
            )
            .then((res) => {
                setPayment({ ...payment, deliveryType: deliveryType });
                setOrderInfo(res.data);
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const changePaymentType = (paymentType) => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/${paymentType}`)
            .then((res) => {
                setPayment({ ...payment, paymentType: paymentType });
                setOrderInfo(res.data);
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const changePaymentTypeToFree = async () => {
        await axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(async () => {
                await confirmShopbasketByOrderId(orderId);
                navigate(`/${language}/confirmation/${orderId}`);
            });
    };

    const setOrderInsurance = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/Insurance`)
            .then((res) => {
                setInsurance(true);
                setOrderInfo(res.data);

                let total = orderInfo.totalPrice;

                if (orderInfo.totalInsuranceAmount === 0 && insurance) {
                    total = total + insuranceTotalAmount;
                }

                total = Math.round(total * 100) / 100;

                let price = total.toFixed(2) + " CHF";

                sessionStorage.setItem("totalPrice", price);

                let dataTransProviders = [1, 7, 8, 9, 10, 14, 11];

                if (
                    dataTransProviders.findIndex((d) => d === payment.paymentType) !== -1
                ) {
                    startDatatrans();
                }
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    const setOrderInsuranceTotalAmount = () => {
        let amount = 0.0;

        order.map((ticket) => {
            if (ticket.price < 50) {
                amount = amount + 0.7;
            } else if (ticket.price > 50 && ticket.price < 75) {
                amount = amount + 0.95;
            }
        });

        setInsuranceTotalAmount(Math.round(100 * amount) / 100);
    };

    // calling the popup
    const startDatatrans = () => {
        var baseRequestUrl = `https://datatrans.ticketino.com/Datatrans/${orderId}/DigitalSignature`;

        axios
            .get(baseRequestUrl)
            .then((res) => {
                let datatransFormId = "datatrans-" + new Date().getTime();
                var form =
                    "<form class='datatrans' id='" + datatransFormId + "'></form>";

                let container = document.getElementById("datatrans-form-placeholder");

                container.innerHTML += form;

                let element = document.getElementById(datatransFormId);

                // merchantId for testing
                // element.setAttribute("data-merchant-id", "1100004624");
                element.setAttribute("data-merchant-id", res.data.merchantId);
                element.setAttribute("data-amount", res.data.amount);
                element.setAttribute("data-currency", res.data.currency);
                element.setAttribute("data-refno", res.data.referenceNumber);
                element.setAttribute("data-reqType", res.data.reqType);
                element.setAttribute(
                    "data-upp-return-target",
                    res.data.uppReturnTarget
                );
                element.setAttribute("data-paymentMethod", res.data.paymentMethod);
                element.setAttribute("data-sign", res.data.digitalSignature);

                let domain = "https://" + window.location.host;

                let successUrl = domain + `/form/redirect/${language}/success/${orderId}`;
                let errorUrl = domain + `/form/redirect/${language}/error/${orderId}`;
                let cancelUrl = domain + `/form/redirect/${language}/cancel/${orderId}`;

                element.setAttribute("data-success-url", successUrl);
                element.setAttribute("data-error-url", errorUrl);
                element.setAttribute("data-cancel-url", cancelUrl);

                for (const key in res.data.userInfo) {
                    element.setAttribute(key, res.data.userInfo[key]);
                }

                for (const key in res.data.merchantSpecificParameters) {
                    element.setAttribute(key, res.data.merchantSpecificParameters[key]);
                }

                // start datatrans -> call the payment form
                window.Datatrans.startPayment({
                    form: "#" + datatransFormId,
                });
            })
            .catch((error) => {
                alert(error.response.data);
            });
    };

    // // confirm the order
    // const confirmOrder = () => {
    //   axios
    //     .put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`)
    //     .then(() => {
    //       navigate(`/${language}/confirmation`);
    //     })
    //     .catch((error) => {
    //       alert(error.response.data);
    //     });
    // };

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            console.error(error)
        }
    };

    const changeDeliveryTypeAndPaymentTypeToDefault = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/DeliveryMethod/1`).then((res) => {
                axios
                    .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/1`).then(() => {
                        // refresh the order values
                        // requestCompletedOrder();
                    }).catch((error) => {
                        console.error(error.response.data);
                    });
            }).catch((error) => {
                console.error(error.response.data);
            });
    }

    const finishOrder = async () => {
        if (insurance) {
            setOrderInsurance();
        } else {
            let total = orderInfo.totalPrice;

            if (total == 0) {
                await changePaymentTypeToFree();
            }

            if (orderInfo.totalInsuranceAmount === 0 && insurance) {
                total = total + insuranceTotalAmount;
            }

            total = Math.round(total * 100) / 100;

            let price = total.toFixed(2) + " CHF";

            sessionStorage.setItem("totalPrice", price);
            let dataTransProviders = [1, 7, 8, 9, 10, 14, 11];

            if (
                dataTransProviders.findIndex((d) => d === payment.paymentType) !== -1
            ) {
                startDatatrans();
            }
        }
    };

    const mapSelectedTickets = () => {
        // Create an object to group tickets by eventName
        const ticketGroups = {};

        // Group the tickets
        order.forEach((ticket) => {
            const ticketTypeInfo = bookedTicketTypeInfo.find((btti) => btti.ticketTypeId === ticket.ticketTypeId);
            if (ticketTypeInfo) {
                const eventName = ticketTypeInfo.eventName;

                if (!ticketGroups[eventName]) {
                    ticketGroups[eventName] = [];
                }

                ticketGroups[eventName].push({
                    ticketName: ticketTypeInfo.name,
                    price: ticket.price.toFixed(2),
                    currency: ticket.currency,
                });
            }
        });

        // Map over the groups and render them
        return (
            <div>
                {Object.keys(ticketGroups).map((eventName, index) => (
                    <div key={index}>
                        <h4 className="mt-4 checkoutEventName">{eventName}</h4>
                        {ticketGroups[eventName].map((ticketInfo, innerIndex) => (
                            <div className="row" key={innerIndex}>
                                <div className="col-md-10 col-8 fs-6">
                                    {ticketInfo.ticketName}
                                </div>
                                <div className="col-md-2 col-4 text-end fs-6">
                                    {ticketInfo.price + " " + ticketInfo.currency}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    const mapAddedPromotionCodes = () => {
        return (
            <div>
                {addedPromotionCodes.map((code, index) => (
                    <div className="row" key={index}>
                        <div className="col-md-6 fs-6 text-success">
                            {code} (Promotion code)
                        </div>
                        <div className="col-md-6 fs-6 text-success text-end">
                            <button className="blank-button" onClick={() => removePromotionCode(code)}>({resources.checkOut.Delete})</button>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const mapExtraCosts = () => {
        return (
            <div>
                <p className={"d-flex justify-content-between align-items-start mb-2 fs-6 mt-2"}>
                    {resources.checkOut.TotalCosts}
                    <span></span>
                    {orderInfo.deliverPaymentFee.toFixed(2) + " " + orderInfo.currency}
                </p>
            </div>
        );
    };

    const mapInsuranceOfTickets = () => {
        return (
            <div>
                <p className={"d-flex justify-content-between align-items-start mb-2 fs-6 mt-2"}>
                    Ticketversicherung
                    <span></span>
                    {insuranceTotalAmount.toFixed(2) + " " + orderInfo.currency}
                </p>
            </div>
        );
    };

    const totalSelectedTickets = () => {
        let total = orderInfo.totalPrice;

        if (orderInfo.totalInsuranceAmount === 0 && insurance) {
            total = total + insuranceTotalAmount;
        }

        total = Math.round(total * 100) / 100;

        return (
            <div>
                <p className={"d-flex justify-content-between align-items-start mb-2"}>
                    <p className="fw-bold fs-6">Total</p>
                    <span></span>
                    <p className="fw-bold fs-6">
                        {orderInfo && total.toFixed(2) + " " + orderInfo.currency}
                    </p>
                </p>
            </div>
        );
    };

    const promotionCodeElement = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-4 col-12 pb-3">
                        <input
                            id="inputCode"
                            type="text"
                            name="promotionCode"
                            className="form-control custom-textbox mt-1"
                            value={promotionCode}
                            onChange={(e) => setPromotionCode(e.target.value)}
                        />
                    </div>
                    <div className="col-md-4 col-3">
                        <button
                            className="btn custom-button text-uppercase"
                            onClick={() => applyPromotionCode()}
                            disabled={promotionCode === ""}
                        >
                            {resources.checkOut.Add}
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {!promotionError.valid ? <p className="text-danger fs-6">
                            {promotionError.text}
                        </p> :
                            <p className="text-success fs-6">
                                {promotionError.text}
                            </p>}
                    </div>
                </div>
            </>
        );
    };

    const applyPromotionCode = () => {
        axios
            .put(
                `${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotionCode}`
            )
            .then((res) => {
                setPromotionError({
                    text: `${resources.checkOut.PromocodeSuccess} (${promotionCode})`,
                    valid: true
                });

                setAddedPromotionCodes((current) => [...current, promotionCode]);

                changeDeliveryTypeAndPaymentTypeToDefault();
                requestCompletedOrder();
            })
            .catch((error) => {
                setPromotionError({
                    text: resources.checkOut.PromocodeError,
                    valid: false
                });
            });
    };

    const removePromotionCode = (code) => {

        axios
            .delete(
                `${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`
            )
            .then((res) => {

                setAddedPromotionCodes((current) =>
                    current.filter((item) => item !== code));

                setPromotionError({
                    text: "",
                    valid: false
                });

                requestCompletedOrder();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-3 text-center">
                    <img className="img-fluid logo" src={logo} alt="img" />
                </div>

                <div className="col-md-6">
                    <div id="datatrans-form-placeholder"></div>

                    {resources.translation && (
                        <div className="container wrapper-checkout">
                            <div className="row mt-5">
                                <h3>{resources.checkOut.Promocode}</h3>
                            </div>
                            <div className="row mt-2">
                                <div>{promotionCodeElement()}</div>
                            </div>

                            {/*delivery types*/}
                            <div className="mt-4">
                                <div className="row">
                                    <h3>{resources.checkOut.Delivery}</h3>
                                </div>
                                <div className="mb-5 mt-2 check-page-box">
                                    <div className="mb-3 mt-4">
                                        <div className="form-group d-flex">
                                            <input
                                                className="checkout-radio"
                                                type="radio"
                                                name="flexRadioDefault1"
                                                checked={payment.deliveryType === 1 ? true : false}
                                                onClick={() => changeDeliveryType(1)}
                                                id="printAtHome"
                                            />
                                            <label
                                                className="form-check-label fs-6 bold"
                                                htmlFor="printAtHome"
                                            >
                                                {resources.checkOut.DeliveryPrintAtHome}
                                                <p className="pt-1 m-0">
                                                    <small>{resources.checkOut.DeliveryNoFee}</small>
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-3 mt-4">
                                        <div className="form-group d-flex">
                                            <input
                                                className="checkout-radio"
                                                type="radio"
                                                name="flexRadioDefault1"
                                                checked={payment.deliveryType === 2 ? true : false}
                                                onClick={() => changeDeliveryType(2)}
                                                id="postMail"
                                            />
                                            <label
                                                className="form-check-label fs-6 bold"
                                                htmlFor="postMail"
                                            >
                                                {resources.checkOut.DeliveryPost}
                                                <p className="pt-1 m-0">
                                                    <small>{resources.checkOut.DeliveryFee}</small>
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/*payment types*/}
                                {totalPrice > 0 && <>
                                    <div className="row">
                                        <h3>{resources.checkOut.Payment}</h3>
                                    </div>
                                    <div className="mb-4 mt-2 check-page-box">
                                        {/*<div className="mb-3">*/}
                                        {/*<div className="form-group d-flex">*/}
                                        {/*    <input*/}
                                        {/*        className="checkout-radio"*/}
                                        {/*        type="radio"*/}
                                        {/*        name="flexRadioDefault"*/}
                                        {/*        checked={payment.paymentType === 2 ? true : false}*/}
                                        {/*        onClick={() => changePaymentType(2)}*/}
                                        {/*        id="invoice"*/}
                                        {/*    />*/}
                                        {/*    <label*/}
                                        {/*        className="form-check-label fs-6 bold"*/}
                                        {/*        htmlFor="invoice"*/}
                                        {/*    >*/}
                                        {/*        {resources.checkOut.PaymmentInvoice}*/}
                                        {/*    <p className="pt-1 m-0">*/}
                                        {/*        <small className="margin-fees">{resources.checkOut.PaymentSmallFee}</small>*/}
                                        {/*    </p>*/}
                                        {/*    </label>*/}
                                        {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="mb-3 mt-2">*/}
                                        {/*    <div className="form-group d-flex">*/}
                                        {/*        <input*/}
                                        {/*            className="checkout-radio"*/}
                                        {/*            type="radio"*/}
                                        {/*            name="flexRadioDefault"*/}
                                        {/*            checked={payment.paymentType === 11 ? true : false}*/}
                                        {/*            onClick={() => changePaymentType(11)}*/}
                                        {/*            id="sofort"*/}
                                        {/*        />*/}
                                        {/*        <label*/}
                                        {/*            className="form-check-label fs-6 bold"*/}
                                        {/*            htmlFor="sofort"*/}
                                        {/*        >*/}
                                        {/*            {resources.checkOut.PaymentSofort}*/}
                                        {/*            <p className="pt-1 m-0">*/}
                                        {/*                <small>{resources.checkOut.PaymentNoFees}</small>*/}
                                        {/*            </p>*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="mb-3 mt-4">*/}
                                        {/*<div className="form-group d-flex">*/}
                                        {/*    <input*/}
                                        {/*        className="checkout-radio"*/}
                                        {/*        type="radio"*/}
                                        {/*        name="flexRadioDefault"*/}
                                        {/*        checked={payment.paymentType === 10 ? true : false}*/}
                                        {/*        onClick={() => changePaymentType(10)}*/}
                                        {/*        id="paypal"*/}
                                        {/*    />*/}
                                        {/*    <label*/}
                                        {/*        className="form-check-label fs-6 bold"*/}
                                        {/*        htmlFor="paypal"*/}
                                        {/*    >*/}
                                        {/*        PayPal*/}
                                        {/*    <p className="pt-1 m-0">*/}
                                        {/*        Zusatzgebühr von CHF + 4.9%.*/}
                                        {/*    </p>*/}
                                        {/*    </label>*/}
                                        {/*</div>*/}
                                        {/*</div>*/}
                                        <div className="mb-3 mt-4">
                                            <div className="form-group d-flex">
                                                <input
                                                    className="checkout-radio"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    checked={payment.paymentType === 1 ? true : false}
                                                    onClick={() => changePaymentType(1)}
                                                    id="mastercard"
                                                />
                                                <label
                                                    className="form-check-label fs-6 bold"
                                                    htmlFor="mastercard"
                                                >
                                                    Mastercard
                                                    <p className="pt-1 m-0">
                                                        <small>{resources.checkOut.PaymentNoFees}</small>
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 mt-4">
                                            <div className="form-group d-flex">
                                                <input
                                                    className="checkout-radio"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    checked={payment.paymentType === 7 ? true : false}
                                                    onClick={() => changePaymentType(7)}
                                                    id="visa"
                                                />
                                                <label
                                                    className="form-check-label fs-6 bold"
                                                    htmlFor="visa"
                                                >
                                                    Visa
                                                    <p className="pt-1 m-0">
                                                        <small>{resources.checkOut.PaymentNoFees}</small>
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 mt-4">
                                            <div className="form-group d-flex">
                                                <input
                                                    className="checkout-radio"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    checked={payment.paymentType === 8 ? true : false}
                                                    onClick={() => changePaymentType(8)}
                                                    id="amex"
                                                />
                                                <label
                                                    className="form-check-label fs-6 bold"
                                                    htmlFor="amex"
                                                >
                                                    American Express
                                                    <p className="pt-1 m-0">
                                                        <small>{resources.checkOut.PaymentNoFees}</small>
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 mt-4">
                                            <div className="form-group d-flex">
                                                <input
                                                    className="checkout-radio"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    checked={payment.paymentType === 9 ? true : false}
                                                    onClick={() => changePaymentType(9)}
                                                    id="postfinance"
                                                />
                                                <label
                                                    className="form-check-label fs-6 bold"
                                                    htmlFor="postfinance"
                                                >
                                                    PostFinance
                                                    <p className=" pt-1 m-0">
                                                        <small>{resources.checkOut.PaymentFee1}</small>
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 mt-4">
                                            <div className="form-group d-flex">
                                                <input
                                                    className="checkout-radio"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    checked={payment.paymentType === 14 ? true : false}
                                                    onClick={() => changePaymentType(14)}
                                                    id="twint"
                                                />
                                                <label
                                                    className="form-check-label fs-6 bold"
                                                    htmlFor="twint"
                                                >
                                                    Twint
                                                    <p className="pt-1 m-0">
                                                        <small>{resources.checkOut.PaymentNoFees}</small>
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-5 mb-5">
                                        <div className="form-group d-flex">
                                            <input
                                                className="checkout-radio"
                                                type="checkbox"
                                                id="insurance-checkbox"
                                                name="event-start"
                                                onClick={() => setTotalAmountWithInsurance(insurance)}
                                                checked={insurance}
                                            />
                                            <label className="ms-3 fs-6" htmlFor="insurance-checkbox">
                                                Mit dem Abschluss der Ticketversicherung{" "}
                                                <span className="fw-bold">
                                                    ({insuranceTotalAmount.toFixed(2) + " CHF"})
                                                </span>{" "}
                                                erhalten Sie im Verhinderungsfall den Ticketpreis zurück
                                                erstattet.
                                            </label>
                                        </div>
                                    </div>
                                </>}
                                {/*summary*/}
                                <div className="row mb-2 mt-5">
                                    <h3>{resources.checkOut.Summary}</h3>
                                </div>
                                <div>
                                    {mapSelectedTickets()}
                                    {insurance && mapInsuranceOfTickets()}
                                    {orderInfo.deliverPaymentFee > 0 && mapExtraCosts()}
                                    {mapAddedPromotionCodes()}
                                    <hr />
                                    {EventGroupId == eventGroupIdKombiTickets &&
                                        <div className="row mb-2">
                                            <span>Es werden zwei Tickets pro Person generiert (1x Yuki Show und 1x Digital Art Show)</span>
                                        </div>
                                    }
                                    {totalSelectedTickets()}
                                </div>
                            </div>

                            {/* next button */}
                            <div className="row mt-5">
                                <div className="col-6 text-start">
                                    <button
                                        className="btn custom-button"
                                        onClick={() => navigate(-1)}
                                    >
                                        {resources.translation._Back}
                                    </button>
                                </div>
                                <div className="col-6 text-end">
                                    <button
                                        className="btn custom-button text-uppercase"
                                        onClick={() => finishOrder()}
                                        disabled={payment.paymentType === 0 && payment.deliveryType === 0}
                                    >
                                        {resources.checkOut.FinishOrder}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <p className="mt-5 text-center">
                        {resources &&
                            (<small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources?._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Step40_Checkout;
