import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import logo from "../img/illuminarium.png";

import Loading from "./components/Loading";
import "../css/style.css"

const Step05_KombiTicketsQuantity = () => {

    const [loading, setLoading] = useState(true);
    const [kombiTicketsQuantity, setkombiTicketsQuantity] = useState(0);

    // eventGroupId from url parameter
    const { eventGroupId } = useParams();

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    const loadToken = () => {
        axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            setLoading(false);
        });
    };


    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div>
            {/* loop the ticket types */}
            {loading ? (
                <Loading
                    alignment="center"
                    color="#d3d3d3"
                    bgColor="transparent"
                    position="fixed"
                    top="50%"
                    left="50%"
                />
            ) : (
                <>
                    {resources && (
                        <div className="container">
                            <div className="row">

                                <div className="col-md-3 text-center">
                                    <img className="img-fluid logo" src={logo} alt="img" />
                                </div>

                                <div className="col-md-6">
                                    <div className="m-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h1>KOMBI TICKETS</h1>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-12 text-center f-size-20px">WIE VIELE KOMBITICKETS WILLST DU BUCHEN?</div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="offset-4 col-4">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    max="30"
                                                    name="kombiQuantity"
                                                    className="form-control custom-textbox"
                                                    value={kombiTicketsQuantity}
                                                    onChange={(e) => setkombiTicketsQuantity(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-6">
                                                <button className="btn custom-button" onClick={() => navigate(-1)}>
                                                    {resources?._Back}
                                                </button>
                                            </div>
                                            <div className="col-6 text-end">
                                                <button className="btn custom-button"
                                                    disabled={!kombiTicketsQuantity >= 1 || kombiTicketsQuantity === '0' || kombiTicketsQuantity > 30}
                                                    onClick={() => navigate(`/${language}/timetable/${eventGroupId}/${kombiTicketsQuantity}`)}>WEITER</button>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mt-5 text-center">
                                        {resources && (
                                            <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                                        )}
                                    </p>
                                </div>

                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Step05_KombiTicketsQuantity;
