import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import logo from "../img/illuminarium.png";
import pdfImage from "../img/pdf-icon.png";

const Step50_Confirmation = () => {
    const [resources, setResources] = useState({});
    const [order, setOrder] = useState({});
    const [organizerId, setOrganizerId] = useState("");

    const [token] = useState(sessionStorage.getItem("token"));

    // fetching resources
    const { language, orderId } = useParams();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestResources();
        requestFormSettings();
    }, [language]);

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            loadOrder(orderId);
        });
    };

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);

            const totalPrice = order.totalPrice;

            setOrder(order);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.log(error.response.data));
    };

    console.log(order);

    return (
        <div className="container">
            {resources.translation && (
                <div className="row">

                    <div className="col-md-3 text-center">
                        <img className="img-fluid logo" src={logo} alt="img" />
                    </div>

                    <div className="col-md-6">

                        <div className="m-5">
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <h1>
                                        Vielen Dank für Deine Anmeldung.
                                    </h1>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <p>Deine Bestell-Nr. lautet {order.invoiceNumber && order.invoiceNumber}</p>
                                        <p>
                                            Bitte notiere Dir Deine Bestell-Nr. für allfällige Support-Anfragen. <br></br>
                                            Du erhältst in Kürze eine Bestellbestätigung per E-Mail.
                                        </p>
                                        <p>
                                            Lade dir jetzt Dein Ticket herunter und drucke es aus.
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-8 mt-4">
                                        <img src={pdfImage} className="pdf-icon" alt="car picture"></img>
                                        <button
                                            className="custom-button form-control pdf-button"
                                            onClick={() => getPdfTicketsOfOrder()}
                                        >
                                            TICKET HERUNTERLADEN
                                        </button>
                                    </div>
                                </div>

                                <div className="row mt-5 mb-4">
                                    <div className="col-6 text-start">
                                        <a className="btn custom-button" href="https://illuminarium.ch/">
                                            {resources.translation._Back}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="mt-5 text-center">
                            {resources.translation && (
                                <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                            )}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Step50_Confirmation;
