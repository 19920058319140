import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import logo from "../img/illuminarium.png";
import Loading from "./components/Loading";

import {
    api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId,
    getTicketTypeNumberOfAvailableTickets
} from "ticketino-api-client";

import "../css/style.css";


const Step20_Tickets = () => {
    const [event, setEvent] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [ticketTypesKombi, setTicketTypesKombi] = useState({});
    const [eventGroup, setEventGroup] = useState({});
    const [promotionKombiTickets, setPromotionKombiTickets] = useState(0);
    const [eventGroupIdDigitalArtShow, setEventGroupIdDigitalArtShow] = useState(0);
    const [selectedTicketTypes] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [order, setOrder] = useState([]);
    const [errors, setErrors] = useState("");
    const [token] = useState(sessionStorage.getItem("token"));
    const [loading, setLoading] = useState(true);


    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();
    const { eventGroupId } = useParams();
    const { eventId } = useParams();
    const { eventId2 } = useParams();
    const { kombiTicketsQuantity } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        sessionStorage.setItem("EventGroupId", eventGroupId);

        if (orderId) {
            deleteOrderTickets();
            loadEvent(eventId, orderId);
        }
    }, [orderId]); //gets called when an order is started

    const deleteOrderTickets = async () => {
        var response = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);

        var tickets = response.data.tickets;

        if (tickets.length > 0) {
            let ticketsInOrder = [];
            tickets.map(ticket => ticketsInOrder.push(ticket.id));

            let body = { "TicketsToRemove": ticketsInOrder }
            axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { headers: { Accept: "application/json" }, data: body });
        }

        return response.data;
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadEvent = async (eventId, orderId) => {
        try {
            setLoading(true);

            // Event
            const event = await getEventById(eventId);
            const infos = await getEventInfosByEventId(eventId);

            sessionStorage.setItem("Event1Info", JSON.stringify(infos.eventInfos));

            if (eventId2 !== undefined) {
                const infos2 = await getEventInfosByEventId(eventId2);
                sessionStorage.setItem("Event2Info", JSON.stringify(infos2.eventInfos));
            }

            // sorting it according to the language
            const eventInfo = infos.eventInfos.find(info => info.languageId == languageId);

            // setting the eventInfo object in the info attribute of event
            const updatedEventInfo = { ...event, info: eventInfo };

            let mergedTicketTypes = [];
            const ticketTypes = await getTicketTypesByEventId(eventId);
            let ticketTypes2 = [];

            // for kombi tickets
            if (eventId2 != null) {
                ticketTypes2 = await getTicketTypesByEventId(eventId2);
            }

            // merge ticketTypes and ticketTypes2
            mergedTicketTypes = [...ticketTypes, ...ticketTypes2];

            sessionStorage.setItem("TicketTypesEvent", JSON.stringify(mergedTicketTypes));

            // todo Markus kombi filter out ticket type, check if both are available, show smallest number on dropdownlist
            // maybe its better if i cann the method bellow instead of merging ticket Types before
            const updatedTicketTypes = await Promise.all(
                mergedTicketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                    // sorting it according to the language
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId);

                    // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                    return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability };
                })
            );

            // when eventgroupId is kombi tickets get promocode for discount
            const formsettings = await axios.get(`form/formsettings`);
            setPromotionKombiTickets(formsettings.data.promotionCodeKombiTickets)
            setEventGroupIdDigitalArtShow(formsettings.data.eventGroupIdDigitalArtShow)

            // for kombi tickets
            if (eventId2 != null) {

                let newUpdatedTicketTypes = [...updatedTicketTypes];
                let groupedArray = [];

                while (newUpdatedTicketTypes.length > 0) {
                    const utt = newUpdatedTicketTypes.shift();
                    const obj = {
                        tt1: utt,
                        tt2: {},
                        price: utt.price // Initialize price with tt1.price
                    };

                    const index = newUpdatedTicketTypes.findIndex(tt => tt.info?.name === utt.info?.name);

                    if (index !== -1) {
                        obj.tt2 = newUpdatedTicketTypes[index];
                        if (index === 1 || index === 2) {
                            obj.price += newUpdatedTicketTypes[index].price - formsettings.data.promotionDiscountKombiTickets; // Add tt2.price to price - promotion discount for adults and teenagers
                        } else {
                            obj.price += newUpdatedTicketTypes[index].price; // Add tt2.price to price

                        } // Add tt2.price to price - promotion discount
                        newUpdatedTicketTypes.splice(index, 1);
                    }

                    groupedArray.push(obj);
                }

                groupedArray = groupedArray.filter(obj => Object.keys(obj.tt2).length > 0);

                setTicketTypesKombi(groupedArray);
            }

            // EventGroup
            const eventGroup = await axios.get(`${baseUrl}/EventGroup/${eventGroupId}`);

            // setting the values in the end
            setEvent(updatedEventInfo);
            setTicketTypes(updatedTicketTypes);
            setEventGroup(eventGroup.data)

            setLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    function formatEventDate(dateString) {

        const eventDate = new Date(dateString);
        const day = eventDate.getUTCDate().toString().padStart(2, '0');
        const month = (eventDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = eventDate.getUTCFullYear().toString();
        const hours = eventDate.getHours().toString().padStart(2, '0');
        const minutes = eventDate.getMinutes().toString().padStart(2, '0');


        if (eventId2 > 0) {
            return `${day}.${month}.${year}`;
        } else {
            return `${day}.${month}.${year} ${hours}:${minutes} UHR`;
        }
    }

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                navigate(`/${language}/shipping`);
            })
            .catch((error) => {
                if (error.response.data == "Ticket type must be bought with another ticket type.") {
                    setErrors(resources?.TicketsErrorMessage);
                } else {
                    setErrors(error.response.data);
                }
            });
    };

    const addKombiTicketToBasket = async () => {
        let addTicketTypes = [];

        selectedTicketTypes.map(stt => {
            if (stt.quantity === "0") return;

            let obj1 = {
                ticketTypeId: stt.ticketTypeId1,
                quantity: stt.quantity
            }

            addTicketTypes.push(obj1);

            let obj2 = {
                ticketTypeId: stt.ticketTypeId2,
                quantity: stt.quantity
            }

            addTicketTypes.push(obj2);
        });

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(async () => {
                await applyPromotionCode();
                navigate(`/${language}/shipping`);
            })
            .catch((error) => {
                if (error.response.data == "Ticket type must be bought with another ticket type.") {
                    setErrors(resources?.TicketsErrorMessage);
                } else {
                    setErrors(error.response.data);
                }
            });
    };

    const applyPromotionCode = async () => {
        await axios
            .put(
                `${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotionKombiTickets}`
            )
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onSubmit = async () => {
        if (eventId2 > 0) {
            await addKombiTicketToBasket();
        } else {
            await addTicketToBasket();
        }
    };

    const onTicketTypeChange = (e, ticketTypeId) => {
        const obj = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
        };

        const existingTicketType = selectedTicketTypes.find(
            (t) => t.ticketTypeId === ticketTypeId
        );

        if (existingTicketType) {
            existingTicketType.quantity = e.target.value;
        } else {
            selectedTicketTypes.push(obj);
        }

        // calcualate quantity and validate
        let quantity = 0;

        selectedTicketTypes.map(s => {
            quantity += s.quantity * 1;
        });

        if (quantity > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

        let totalQuantity = 0;
        let totalPrice = 0;

        selectedTicketTypes.forEach((stt) => {
            totalQuantity = totalQuantity + stt.quantity * 1;

            totalPrice = totalPrice + ticketTypes.find((tt) => tt.id === stt.ticketTypeId).price *
                stt.quantity;
        });

        setOrderTotal(totalPrice);
    };

    const onTicketTypeKombiChange = (e, ticketTypeId1, ticketTypeId2) => {
        const obj = {
            ticketTypeId1: ticketTypeId1,
            ticketTypeId2: ticketTypeId2,
            quantity: e.target.value,
        };

        const existingTicketType = selectedTicketTypes.find(
            (t) => t.ticketTypeId1 === ticketTypeId1
        );

        if (existingTicketType) {
            existingTicketType.quantity = e.target.value;
        } else {
            selectedTicketTypes.push(obj);
        }

        // Calculate quantity and validate
        let quantity = 0;

        selectedTicketTypes.map(s => {
            quantity += s.quantity * 1;
        });

        if (quantity > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

        // Calculate total price for kombi tickets
        let totalPrice = 0;

        selectedTicketTypes.forEach((stt) => {
            const ticket1 = ticketTypesKombi.find((tt) => tt.tt1.id === stt.ticketTypeId1);

            if (ticket1) {
                const price = ticket1.price;
                totalPrice += price * stt.quantity;
            }
        });

        setOrderTotal(totalPrice);
    };

    const mapTicketTypes = () => {
        let ticketTypesSorted = [];

        if (ticketTypes && ticketTypes.length > 0) {
            ticketTypesSorted = [...ticketTypes].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        if (eventGroupId == eventGroupIdDigitalArtShow) {
            console.log(ticketTypesSorted);
        }

        return (
            ticketTypes &&
            ticketTypes.length > 0 &&
            ticketTypesSorted.map((tt, index) => (
                <div key={index}>
                    {tt.availability?.status === 0 && eventGroupId == eventGroupIdDigitalArtShow && tt.info?.name.includes("Kinder") ? null : (
                        <div className="row mt-4 mb-3">
                            <div className="col-md-7">
                                <span className="text-uppercase f-size-20px">
                                    {tt.info?.name}
                                </span>
                                <div>
                                    <small>
                                        {tt.info?.description}
                                    </small>
                                </div>
                            </div>
                            <div className="col-md-3 text-md-end">
                                <span className="f-size-20px">
                                    {tt.currency} {tt.price.toFixed(2)}
                                </span>
                            </div>
                            <div className="col-md-2">
                                <select
                                    className="form-select"
                                    onChange={(e) => onTicketTypeChange(e, tt.id)}>
                                    {Array.from(
                                        Array(
                                            tt.availability?.availableTickets + 1
                                        ),
                                        (e, i) => (
                                            <option key={i} value={i}>
                                                {i}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            ))
        );
    };

    const mapTicketTypesKombi = () => {
       
        //need to limit the quantity of tickets bookeable by kombiTicketsQuantity
        //defined on step_05KombiTicketsQuantity
        let totalQuantityAdded = 0;

        selectedTicketTypes.map(t => {
            totalQuantityAdded = parseInt(t.quantity , 10 ) + totalQuantityAdded;
        });

        return (
            <div>
                {kombiTicketsQuantity &&
                    ticketTypesKombi &&
                    ticketTypesKombi.length > 0 &&
                    ticketTypesKombi.map((ticket, index) => {

                        let kombiTicketsQuantityLeft = kombiTicketsQuantity - totalQuantityAdded;
                        
                        selectedTicketTypes.map(t => {
                            if (t.ticketTypeId1 == ticket.tt1.id && t.ticketTypeId2 == ticket.tt2.id) {
                                kombiTicketsQuantityLeft = parseInt(t.quantity , 10 ) + kombiTicketsQuantityLeft; 
                            }
                        });

                        let availableTickets = Math.min(
                            ticket.tt1.availability.availableTickets,
                            ticket.tt2.availability.availableTickets,
                            kombiTicketsQuantityLeft
                        );

                        return (
                            <div key={index}>
                                <div className="row mt-4 mb-3">
                                    <div className="col-md-7">
                                        <span className="text-uppercase f-size-20px">
                                            {ticket.tt1.info.name}
                                        </span>
                                        <div>
                                            <small>
                                                {ticket.tt1.info.description}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-md-3 text-md-end">
                                        <span className="f-size-20px">
                                            {ticket.tt1.currency} {ticket.price.toFixed(2)}
                                        </span>
                                    </div>
                                    <div className="col-md-2">
                                        <select
                                            className="form-select"
                                            onChange={(e) => onTicketTypeKombiChange(e, ticket.tt1.id, ticket.tt2.id)}>
                                            {Array.from(
                                                Array(availableTickets + 1),
                                                (e, i) => {
                                                    return (
                                                        <option key={i} value={i}>
                                                            {i}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                </div>

                            </div>
                        )
                    }
                    )}
            </div>
        );
    };

    return (
        <div className="container">
            {loading
                ? (
                    <Loading
                        alignment="center"
                        color="#d3d3d3"
                        bgColor="transparent"
                        position="fixed"
                        top="50%"
                        left="50%" />
                ) : (
                    <>
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <img className="img-fluid logo" src={logo} alt="img" />
                            </div>

                            <div className="col-md-6">
                                {eventGroup && event &&
                                    <div className="row mt-5">
                                        <div className="col-md-12">
                                            <h2>TICKETKATEGORIEN {eventGroup.name} - {formatEventDate(event.start)}</h2>
                                        </div>
                                    </div>
                                }
                                <div className="mt-5">
                                    <div>
                                        {eventId2 > 0 ? mapTicketTypesKombi() : mapTicketTypes()}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <span className="f-size-20px">SUMME</span>
                                    </div>
                                    <div className="col-md-2">
                                        <span className="f-size-20px">CHF {orderTotal.toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className="row mt-5 mb-4">
                                    <div className="col-6 text-start">
                                        <button className="btn custom-button" onClick={() => navigate(-1)}>
                                            {resources && resources._Back}
                                        </button>
                                    </div>
                                    <div className="col-6 text-end">
                                        <button className="btn custom-button" disabled={disabled} onClick={() => onSubmit()}>
                                            WEITER
                                        </button>
                                    </div>
                                </div>
                                {errors !== "" && (
                                    <div className="row text-end">
                                        <div cassName="offset-md-6 col-md-6"></div>
                                        <p className="text-danger ps-2">{errors}</p>
                                    </div>
                                )}
                            </div>
                            <p className="mt-5 text-center">
                                {resources &&
                                    (
                                        <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources?._TicketinoOrganizerUrl
                                        } target="_blank">TICKETINO</a></small>
                                    )}
                            </p>
                        </div>
                    </>
                )}
        </div>
    );
};

export default Step20_Tickets